import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './AddProduct.css';

const AddProduct = () => {
    const [title, setTitle] = useState('');
    const [images, setImages] = useState(Array(4).fill(null)); // Four compulsory images
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    const handleFileChange = (index, e) => {
        const newImages = [...images];
        newImages[index] = e.target.files[0];
        setImages(newImages);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('title', title);
        formData.append('add_product', '1'); // Add this line

        // Append images
        images.forEach((image, index) => {
            if (image || index === 0) { // Require first image, others are optional
                formData.append('images[]', image);
            }
        });

        try {
            const response = await axios.post('https://www.satindarpalrubberproducts.com/my_project/add_product.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // Check if the response has a success message and handle it correctly
            if (response.data.message) {
                setSuccessMessage(response.data.message);
                setErrorMessage('');
                resetForm();
            } else if (response.data.error) {
                setErrorMessage(response.data.error);
                setSuccessMessage('');
            }
        } catch (error) {
            // Handle different types of errors here
            if (error.response) {
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
                setErrorMessage(error.response.data.error || 'An error occurred.');
            } else if (error.request) {
                console.error('Error request:', error.request);
                setErrorMessage('No response received from server.');
            } else {
                console.error('Error message:', error.message);
                setErrorMessage('Error in setting up the request.');
            }
            setSuccessMessage('');
        }
    };

    const resetForm = () => {
        setTitle('');
        setImages(Array(4).fill(null));
    };

    return (
        <div className="add-product-container">
            <h2>Add Product</h2>
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <form method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Title:</label>
                    <input 
                        type="text" 
                        value={title} 
                        onChange={(e) => setTitle(e.target.value)} 
                        placeholder="Enter product title" 
                        required 
                    />
                </div>

                {images.map((image, index) => (
                    <div className="form-group" key={index}>
                        <label>{`Image ${index + 1}:`}</label>
                        <input 
                            type="file" 
                            accept="image/*" 
                            onChange={(e) => handleFileChange(index, e)} 
                            required={index === 0} // First image is required
                        />
                    </div>
                ))}

                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default AddProduct;

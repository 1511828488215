import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home'; 
import Header from './Header'; 
import Products from './Products'; 
import AdminLogin from './AdminLogin'; 
import AddProduct from './AddProduct'; // Import AddProduct component
import ContactUs from './ContactUs';  // Import ContactUs component
import NotFound from './NotFound'; 
import AdminInquiries from './AdminInquiries'; // Adjust path as necessary
import WhatsAppButton from './WhatsappButton'; // Import the WhatsAppButton component
import './App.css';
import AboutUs from './AboutUs'; // Import AboutUs component
import Footer from './Footer'; // Import the Footer component
import Copyright from './Copyright'; // Import the new Copyright component
import './Styles.css'
import ViewEnquirys from './ViewEnquirys';
function App() {
  const [isAdmin, setIsAdmin] = useState(false); 

  const handleLoginSuccess = () => {
    setIsAdmin(true); 
  };

  return (
    <Router>
      <div className="App">
        <Header isAdmin={isAdmin} />

        {/* Routes for different pages */}
        <Routes>
          <Route path="/" element={<Home />} /> 
          <Route path="/products" element={<Products isAdmin={isAdmin} />} /> 
          <Route 
            path="/admin-login" 
            element={<AdminLogin onLoginSuccess={handleLoginSuccess} />} 
          />
          <Route path="/add-product" element={<AddProduct />} /> 
          <Route path="/contact" element={<ContactUs />} /> {/* Add route for ContactUs */}
          <Route path="/admin-enquiry" element={<AdminInquiries />} />
          <Route path="/about" element={<AboutUs />} /> {/* Add route for About Us */}
          <Route path="*" element={<NotFound />} /> 
          <Route path="/enquiry" element={<ViewEnquirys />} />
          
        </Routes>

        {/* WhatsApp Button will always be visible */}
        <WhatsAppButton />

         {/* Footer Component */}
         <Footer />
         <Copyright />
      </div>
    </Router> 
  );
}

export default App;

import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Column 1: Factory and Office Addresses */}
        <div className="footer-section">
          <h3>Factory Address</h3>
          <p>Plot No A-1/311, Road No 3, G.I.D.C, Sarigam, Dist. Valsad, Gujarat, Sarigam INA, Gujarat 396155</p>
          <h3>Office Address</h3>
          <p style={{ marginTop: "5px" }}>Shop no.205, Raghuleela Mega Mall, Boraspada Rd, Kandivali West, Mumbai, Maharashtra 400067</p>
        </div>

        {/* Column 2: Contact and Quick Links */}
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p>Phone (Factory): +91 9924644666</p>
          <p>Phone (Office): +91 9152950505</p>
          <p>CEO: Satindarpal Singh</p>
          <h3 style={{ textAlign: "center" }}>Quick Links</h3> {/* Center the title */}
          <ul>
            <li><a href="/products">Products</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/contact">Contact Us</a></li>
          </ul>
        </div>

        {/* Column 3: Follow Us and Certifications */}
        <div className="footer-section">
          <h3>Follow Us</h3>
          <a href="https://www.linkedin.com/company/satindarpal-rubber-products-pvt-ltd" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          <h3 style={{ marginTop: "5px" }}>Certifications</h3> {/* Shift the title one line down */}
          <img src="/imageedit_6_2140839653.gif" alt="ISO Certification" className="iso-logo" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;

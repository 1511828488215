import React from 'react';
import './AboutSection.css'; // Custom styling for the About section

const AboutSection = () => {
  return (
    <div className="about-container">
      <div className="about-text">
        <h2>About Us</h2>
        <p>
				Satindarpal Rubber Products Pvt. Ltd. is an esteemed ISO-certified manufacturer, exporter, and supplier of precision-engineered rubber industrial supplies. Our products find application in a wide range of sectors, including oil&gas, energy sector, cementing,petrochemicals and various industries. Leveraging our state-of-the-art manufacturing facility and a highly skilled engineering team, we provide bespoke solutions through a swift and efficient manufacturing process. Our unwavering commitment to quality and timely delivery has earned the trust of numerous clients across India and internationally, supported by  years of industry excellence.
        </p>
      </div>
      <div className="iso-certificate">
        <img src="/SRP_ISO Certificate.png" alt="ISO Certificate" className="iso-image" />
      </div>
    </div>
  );
};

export default AboutSection;

import React from 'react';
import { Link } from 'react-router-dom';
import './AboutUs.css'; // Import the CSS file for styling

const AboutUs = () => {
  return (
    <div className="about-us-container">
      {/* Content Wrapper */}
      <div className="about-us-content">
        
        {/* Text Section */}
        <div className="about-us-text">
          <h1>Satindarpal Rubber Products Pvt Ltd</h1>
          <p>
            Satindarpal Rubber Products Pvt Ltd is one of the leading Manufacturers, Exporters, and Suppliers of a wide range of Products. 
            We are situated in Gujarat, India and we proudly boast of being able to cater to customers in the country and overseas.
          </p>

          {/* Photo above Factory Resources */}
          <div className="about-us-photo">
            <img src="/SRP_Reception_Revised.jpeg" alt="Factory Image" />
          </div>

          <h2>Factory Resources</h2>
          <p>
            We have a spacious manufacturing facility endowed with high-performance Rubber Injection and Compression Molding Machines 
            that enable us to create customer Products. In addition, our factory is incorporated with various other manufacturing 
            equipment and packaging machines. Our factory empowers us to meet bulk requirements and meet varied demands of customers.
          </p>

          <h2>Individually Tailored Solutions</h2>
          <p>
            We have resources to manufacture products as per specifications provided by customers. Following are the aspects we can provide 
            customization on:
          </p>
          <ul className="custom-bullets">
            <li>Design</li>
            <li>Styles</li>
            <li>Grade of rubber</li>
            <li>Dimensions</li>
            <li>Finish</li>
          </ul>

          <h2>Inventory Control Management</h2>
          <p>
            We are able to keep records of raw materials, maintenance equipment/supplies, work in progress, and finished goods, 
            owing to our highly sophisticated inventory management systems. It keeps data to ensure we always have sufficient products, 
            raw material, and other resource availability to meet bulk requirements. Our system enables us to eliminate confusion 
            and protect against stock out.
          </p>

          {/* Admin Login Hidden Black Dot */}
          <div className="admin-login">
            <Link to="/admin-login">
              <span className="admin-login-dot">•</span> {/* Simple black dot */}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

// Copyright.js
import React from 'react';
import './Copyright.css';

const Copyright = () => {
  return (
    <div className="copyright">
      <p>&copy; 2024 All rights reserved</p>
    </div>
  );
};

export default Copyright;

import React from 'react';
import './ContactUs.css'; // Ensure you have this CSS file

export const ContactUs = () => {
  return (
    <div className="contact-us-page">
      <h1 className="contact-page-heading">Let's get in touch</h1>

      <div className="contact-content">
        {/* Form Section */}
        <div className="contact-form-container">
          <h2 className="contact-form-subheading">Please feel free to ask your queries to us!</h2>
          <form className="contact-form" action="https://www.satindarpalrubberproducts.com/my_project/contactus.php" method="post">
            <input type="text" id="name" name="name" placeholder="Your Name *" required />
            <input type="email" id="email" name="email" placeholder="Email Address *" required />
            <input type="text" id="address" name="address" placeholder="Address *" required />
            <input type="tel" id="telephone" name="telephone" placeholder="Telephone *" required />
            <p id="telephoneError" className="error"></p>
            <input type="text" id="organization" name="organization" placeholder="Your Organization *" required />
            <textarea id="message" name="message" placeholder="Your Message (Optional)"></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>

        {/* Google Map Section */}
        <div className="google-map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.559169034696!2d72.85132906748875!3d20.294145416565122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0d3b32a6e57e1%3A0x98df35b81cbca28!2sSatindarpal%20Rubber%20Products%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1727682499409!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

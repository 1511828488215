import React from 'react';
import './WhatsAppButton.css'; // Import the CSS for styling

const WhatsAppButton = () => {
  const phoneNumber = "9924644666"; // Replace with your phone number
  const message = "Hi, I wanted to enquire about rubber products."; // Pre-filled message

  const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

  return (
    <a
      href={whatsappURL}
      className="whatsapp-float"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="/WhatsApp Image 2024-09-28 at 11.05.58.jpeg" // Ensure you have the WhatsApp icon in your project
        alt="WhatsApp"
        className="whatsapp-icon"
      />
    </a>
  );
};

export default WhatsAppButton;

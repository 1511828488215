import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ViewEnquiry.css'; // Import CSS for styling

const ViewEnquirys = () => {
    const [inquiries, setInquiries] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchInquiries = async () => {
            try {
                const response = await axios.get('https://www.satindarpalrubberproducts.com/my_project/viewenquiry.php');
                if (response.data.error) {
                    setErrorMessage(response.data.error);
                } else {
                    setInquiries(response.data);
                }
            } catch (error) {
                setErrorMessage('An error occurred while fetching inquiries.');
                console.error('Error fetching inquiries:', error);
            }
        };

        fetchInquiries();
    }, []);

    return (
        <div className="view-enquiry-container">
            <h2>View Inquiries</h2>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {inquiries.length > 0 ? (
                <table className="inquiry-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Image</th>
                            <th>Timestamp</th>
                            {/* Add more headers as per your table columns */}
                        </tr>
                    </thead>
                    <tbody>
                        {inquiries.map((inquiry) => (
                            <tr key={inquiry.id}>
                                <td>{inquiry.id}</td>
                                <td>{inquiry.title}</td>
                                <td>
                                    {inquiry.image && (
                                        <img src={`https://www.satindarpalrubberproducts.com/my_project/images/${inquiry.image}`} alt={inquiry.title} style={{ width: '100px', height: 'auto' }} />
                                    )}
                                </td>
                                <td>{new Date(inquiry.timestamp).toLocaleString()}</td>
                                {/* Add more data cells as per your table columns */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No inquiries found.</p>
            )}
        </div>
    );
};

export default ViewEnquirys;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './AdminInquiries.css'; // Import CSS file for styling

const AdminInquiries = () => {
    const [inquiries, setInquiries] = useState([]);
    const [selectedIds, setSelectedIds] = useState(new Set()); // State to hold selected inquiry IDs

    useEffect(() => {
        const fetchInquiries = async () => {
            try {
                const response = await axios.get('https://www.satindarpalrubberproducts.com/inquiries');
                setInquiries(response.data); // Set inquiries data from response
            } catch (error) {
                console.error("Error fetching inquiries:", error);
            }
        };

        fetchInquiries(); // Call the function to fetch inquiries
    }, []); // Empty dependency array ensures this runs once when the component mounts

    const handleCheckboxChange = (id) => {
        const newSelectedIds = new Set(selectedIds);
        if (newSelectedIds.has(id)) {
            newSelectedIds.delete(id); // Remove from selected IDs if already selected
        } else {
            newSelectedIds.add(id); // Add to selected IDs
        }
        setSelectedIds(newSelectedIds);
    };

    const deleteSelectedInquiries = async () => {
        try {
            await Promise.all(
                [...selectedIds].map(id => axios.delete(`https://www.satindarpalrubberproducts.com/inquiries/${id}`))
            );
            // Refresh the inquiries list after deletion
            const response = await axios.get('https://www.satindarpalrubberproducts.com/inquiries');
            setInquiries(response.data);
            setSelectedIds(new Set()); // Reset selected IDs
        } catch (error) {
            console.error("Error deleting inquiries:", error);
        }
    };

    return (
        <div className="admin-inquiries">
            <h2>All Inquiries</h2>
            <button onClick={deleteSelectedInquiries} disabled={selectedIds.size === 0}>
                Delete Selected
            </button>
            <table>
                <thead>
                    <tr>
                        <th>Select</th> {/* Checkbox column */}
                        <th>Name</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>Telephone</th>
                        <th>Organization</th>
                        <th>Message</th>
                        <th>Quantity</th> {/* New column */}
                        <th>Unit Type</th> {/* New column */}
                    </tr>
                </thead>
                <tbody>
                    {inquiries.map((inquiry) => (
                        <tr key={inquiry.id}>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedIds.has(inquiry.id)}
                                    onChange={() => handleCheckboxChange(inquiry.id)}
                                />
                            </td>
                            <td>{inquiry.name}</td>
                            <td>{inquiry.email}</td>
                            <td>{inquiry.address}</td>
                            <td>{inquiry.telephone}</td>
                            <td>{inquiry.organization}</td>
                            <td>{inquiry.message}</td>
                            <td>{inquiry.quantity}</td> {/* New data */}
                            <td>{inquiry.unit_type}</td> {/* New data */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AdminInquiries;

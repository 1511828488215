import React from 'react';
import Slideshow from './Slideshow';
import AboutSection from './AboutSection';
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <section className="home-content-section">
        {/* Slideshow */}
        <Slideshow />

        {/* About Section */}
        <AboutSection />
      </section>
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Products.css';

const Products = () => {
    // State declarations
    const [products, setProducts] = useState([]);
    const [currentImageIndexes, setCurrentImageIndexes] = useState({});
    const [overlayVisible, setOverlayVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null); // State for selected product for enquiry
    const [material, setmaterial] = useState('');
    const [quantity, setQuantity] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');

    // Fetch products from API
    const fetchProducts = async () => {
        try {
            const response = await axios.get('https://www.satindarpalrubberproducts.com/my_project/product.php');
            if (Array.isArray(response.data)) {
                setProducts(response.data);
            } else {
                console.error("Response is not an array:", response.data);
            }
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };
    

    // Effect to fetch products on component mount
    useEffect(() => {
        fetchProducts();
    }, []);

    // Effect to initialize image indexes based on fetched products
    useEffect(() => {
        if (products.length) {
            const initialIndexes = products.reduce((acc, _, index) => {
                acc[index] = 0;
                return acc;
            }, {});
            setCurrentImageIndexes(initialIndexes);
        }
    }, [products]);

    // Handlers for image navigation
    const handleNextImage = (index, imageCount) => {
        setCurrentImageIndexes((prev) => ({
            ...prev,
            [index]: (prev[index] + 1) % imageCount,
        }));
    };

    const handlePrevImage = (index, imageCount) => {
        setCurrentImageIndexes((prev) => ({
            ...prev,
            [index]: (prev[index] - 1 + imageCount) % imageCount,
        }));
    };

    // Handlers for overlay visibility
    const handleImageMouseEnter = () => {
        setOverlayVisible(true);
    };

    const handleImageMouseLeave = () => {
        setOverlayVisible(false);
    };

    const handleArrowMouseEnter = () => {
        setOverlayVisible(false);
    };

    const handleArrowMouseLeave = () => {
        setOverlayVisible(true);
    };

    // Handler for 'Enquire Now' button
    const handleEnquireNow = (product) => {
        setSelectedProduct(product); // Set the selected product
        setOverlayVisible(false); // Close image overlay
    };

    // Handler to close the enquiry dialog
    const handleCloseDialog = () => {
        setSelectedProduct(null);
        // Reset form inputs after closing the dialog
        setmaterial('');
        setQuantity('');
        setPhoneNumber('');
        setEmail('');
    };

    // Handle enquiry submission
// Handle enquiry submission
const handleEnquirySubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    const enquiryData = {
        name: selectedProduct.title || '',  // If you want the product name as the 'name'
        email,  // User's email
        phoneNumber,  // User's phone number
        quantity,  // Quantity filled by the user
        material,  // Unit type filled by the user
        message: '',  // Additional optional field, could be added later
        organization: '',  // Leave as blank (optional for now)
        address: ''  // Leave as blank (optional for now)
    };

    console.log(enquiryData);  // Add this line to log the enquiry data before submitting

    try {
        const response = await axios.post('https://www.satindarpalrubberproducts.com/my_project/Enquiry.php', enquiryData);
        console.log('Enquiry submitted successfully:', response.data);
        // Reset form inputs after submission
        setmaterial('');
        setQuantity('');
        setPhoneNumber('');
        setEmail('');
        setSelectedProduct(null);  // Close the enquiry dialog
    } catch (error) {
        console.error('Error submitting enquiry:', error);
    }
};


    // Group products by type
    const groupedProducts = products.reduce((acc, product) => {
        const { type } = product;
        if (!acc[type]) {
            acc[type] = [];
        }
        acc[type].push(product);
        return acc;
    }, {});

    return (
        <div>
            <div className="product-sections">
                {Object.entries(groupedProducts).map(([type, products]) => (
                    <div key={type} className="product-type-section">
                        <div className="product-grid">
                            {products.map((product, index) => {
                            const imageUrls = Array.isArray(product.photos)
                            ? product.photos.map(photo => ` https://www.satindarpalrubberproducts.com/my_project/${photo}`)
                            : [];
                                console.log('Image URLs:', imageUrls); // Check what URLs are being generated
                                const currentImageIndex = currentImageIndexes[index] !== undefined ? currentImageIndexes[index] : 0;

                                return (
                                    <div key={product.id} className="product-card">
                                        <div
                                            className="image-container"
                                            onMouseEnter={handleImageMouseEnter}
                                            onMouseLeave={handleImageMouseLeave}
                                        >
                                            <img
                                                src={imageUrls[currentImageIndex] || 'path/to/placeholder-image.png'}
                                                alt={product.title}
                                                className="product-image"
                                                width="300"
                                                onError={(e) => { e.target.src = 'path/to/placeholder-image.png'; }}
                                            />

                                            {/* Black transparent overlay */}
                                            {overlayVisible && (
                                                <div className="image-overlay">
                                                    <div className="overlay-title">{product.title}</div>
                                                    <div className="button-container">
                                                        <button className="enquire-button" onClick={() => handleEnquireNow(product)}>
                                                            Enquire Now
                                                        </button>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="arrow-container">
                                                {imageUrls.length > 1 && (
                                                    <>
                                                        <button
                                                            className="arrow left-arrow"
                                                            onClick={() => handlePrevImage(index, imageUrls.length)}
                                                            onMouseEnter={handleArrowMouseEnter}
                                                            onMouseLeave={handleArrowMouseLeave}
                                                        >
                                                            &#10094;
                                                        </button>
                                                        <button
                                                            className="arrow right-arrow"
                                                            onClick={() => handleNextImage(index, imageUrls.length)}
                                                            onMouseEnter={handleArrowMouseEnter}
                                                            onMouseLeave={handleArrowMouseLeave}
                                                        >
                                                            &#10095;
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <h4>{product.title}</h4>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>

            {/* Enquiry Dialog */}
{selectedProduct && (
    <div className="enquiry-dialog">
        <div className="dialog-content">
            <button className="cancel-button" onClick={handleCloseDialog}>✖</button>
            <h3 className="dialog-header">Want to Enquire about {selectedProduct.title}?</h3>
            <div className="dialog-body">
                {/* Left: Image */}
                <div className="dialog-image-container">
                    {/* Log the photos to check structure */}
                    {console.log('Selected Product Photos:', selectedProduct.photos)}
                    <img
                        // Adjusted image URL to access the correct folder
                        src={`https://www.satindarpalrubberproducts.com/my_project/${selectedProduct.photos[0]}`}

                        
                        alt={selectedProduct.title}
                        className="dialog-image"
                        onError={(e) => { e.target.src = 'path/to/placeholder-image.png'; }} // Optional: Fallback image
                    />
                </div>
                {/* Right: Form and details */}
                <div className="dialog-details">
                    <form className="dialog-form" onSubmit={handleEnquirySubmit}>
                        <div className="form-row">
                            <input
                                type="text"
                                placeholder="Material"
                                className="form-input"
                                value={material}
                                onChange={(e) => setmaterial(e.target.value)}
                                required
                            />
                            <input
                                type="number"
                                placeholder="Quantity"
                                className="form-input"
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-row">
                            <input
                                type="tel"
                                placeholder="Phone Number"
                                className="form-input"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                required
                            />
                        </div>
                        <input
                            type="email"
                            placeholder="Email ID"
                            className="form-input"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        
                        <div className="enquiry-button-container">
                            
                            <button type="submit" className="send-enquiry-button">Send Enquiry</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
)}

        </div>
    );
};

export default Products;
